<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">병원관리자 정보</v-card-text>
    </v-col>
    <v-col cols="12">
      <SearchTopUIComp
        v-if="reqData.hospital_id > 0"
        :placeholder="$t('common.component.searchTop.searchInputWord')"
        :propsSearchWorld="reqData.searchVal"
        @changeText="changeText"
        @changeSearchWordComplete="changeSearchWordComplete"
      />
    </v-col>
    <v-col cols="12">
      <SelectBoxClinic @updateSelect="updateSelectClinic" />
    </v-col>
    <v-col cols="12">
      <TopTable
        v-if="reqData.hospital_id > 0"
        :btnLabel="$t('common.button.new')"
        :btnIsShow="reqData.hospital_id === 0 ? false : true"
        :IsSwitchShow="reqData.hospital_id === 0 ? false : true"
        :xlsxBtnIsShow="false"
        :switchLabel="switchLabel"
        :data="staffData"
        @clickXlsxBtn="xlsxSave"
        @clickEditorBtn="clickNewBtn"
        @clickReload="clickReload"
        @changeSwitch="changeSwitch"
      />
    </v-col>
    <v-col cols="12">
      <DataTable
        ref="DataTable"
        :loading="loading"
        :loadingText="loadingText"
        :tableKey="'rowSeq'"
        :perPage="10000"
        :footerHide="true"
        :singleSelect="true"
        :showSelect="false"
        :columns="userInfoColumns"
        :inputData="staffData"
        @clickRow="clickRow"
      />
    </v-col>

    <!-- add / edit -->
    <DialogStaffAdd ref="refStaffAdd" @complete="addComplete" />
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SearchTopUIComp from "@/components/commonV2/ui/SearchTopUIComp.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import TopTable from "@/components/commonV2/ui/TopTable.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogStaffAdd from "@/components/commonV2/Dialog/DialogStaffAdd.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");

export default {
  components: {
    SearchTopUIComp,
    TopTable,
    DataTable,
    SelectBoxClinic,
    DialogStaffAdd,
  },
  data() {
    return {
      loading: false,
      loadingText: "Loading list...",
      userInfoColumns: formatService.staffTableColumnsV1(),
      staffData: [],
      switchLabel: this.$t('common.activeLabel'),
      userData: modelService.staffModel(),
      propsDate: [this.$moment().subtract(30, "year"), this.$moment()],
      reqData: modelService.reqDataModel(),
      isDev: process.env.NODE_ENV === "development",
    };
  },
  mounted() {
    if (this.meInfo.userType !== "super")
      this.reqData.hospital_id = this.meInfo.hospital_id;
    this.getData();

    // if (this.isDev) this.clickNewBtn();
  },
  methods: {
    /**** 클리닉 선택 ***/
    updateSelectClinic(e) {
      this.reqData.hospital_id = e === "" ? 0 : e;
      this.getData();
    },
    /**** 검색어 ***/
    changeSearchWordComplete(e) {
      this.reqData.searchVal = e;
      this.getData();
    },
    changeText(e) {
      this.reqData.searchVal = e;
    },
    /**** 중간 ***/
    changeSwitch(e) {
      this.reqData.activated = e.length > 0 ? true : false;
      this.getData();
    },
    xlsxSave() {
      this.$helper.xlsxDownload(this.xlsxData, "sheet1", "StaffList");
    },
    clickNewBtn() {
      console.log("", this.reqData.hospital_id);
      this.userData.hospital_id = this.reqData.hospital_id;
      this.$refs.refStaffAdd.Open(this.userData);
    },
    clickReload() {
      this.reqData.searchVal = "";
      this.selectedBtn = "all"; // 초기화할때 버튼과 date 값을 초기화 해야함
      this.propsDate = [this.$moment().subtract(30, "year"), this.$moment()];
      this.getData();
    },
    clickRow(e) {
      this.$refs.refStaffAdd.Open(e);
    },
    /**** 데이터읽기 ***/
    async getData() {
      const $h = this.$helper;
      this.reqData.endpoint = "staff";
      this.reqData.fromDay = $h.changeDateToString(this.propsDate[0],false);
      this.reqData.toDay = $h.changeDateToString(this.propsDate[1],false);
      await this.getDataQuery(this.reqData).then((resP) => {
        console.log("resP", resP);
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            staffName: item.name,
            regDateLabel: $h.changeDateToStringForDp(item.regDate,true),
            updDateLabel: $h.changeDateToStringForDp(item.updDate,true),
            useYNLabel: $h.memberTypeLbl(item.activated),
            isPhoto: $h.isPhotoChecker(item.userPhotoImg),
            dpDateLbl: $h.changeDateToForUpdateDp(item.regDate,item.updDate),
          }));
        }
        // console.log(res);
        this.staffData = res;
      });
    },
    /**** 저장/수정/삭제 후 처리 ***/
    addComplete(e) {
      this.getData();
    },
    ...mapActions(["getDataQuery"]),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>
<style lang="scss">
._gap {
  display: inline-block;
  height: 2px;
}
</style>